import { logout, login, getMemberInfo, setDropColumnHead } from '@/api/member'
import {
  getRemToken,
  setRemToken,
  removeRemToken,
  getToken,
  setToken,
  removeToken,
  getHeadCache,
  setHeadCache,
} from '@/utils/auth'
import { resetRouter } from '@/router'
import { isEmpty, isNotEmpty, showMessageTips } from '@/utils/index'
import Cookies from 'js-cookie'

const getDefaultState = () => {
  return {
    memberInfo: {},
    headCache: {},
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_MEMBERINFO: (state, memberInfo) => {
    state.memberInfo = memberInfo
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_HEADCACHE: (state, headCache) => {
    state.headCache = headCache
  },
}

const actions = {
  // user login
  async login({ commit }, userForm) {
    const { username, password, verifyCode, remember_me } = userForm

    let remToken = ''
    if (remember_me) {
      remToken = getRemToken()
      if (isNotEmpty(remToken)) {
        let rems = remToken.split(';')
        remToken = rems[0]
      }
    } else {
      removeRemToken()
    }

    //登录请求
    const response = await login({
      username: username.trim(),
      password: password,
      verifyCode: verifyCode,
      remFlag: remember_me,
      remToken: remToken,
    }).then(res => {
      const { token, data } = res

      let remember_token = data.remember_token
      if (remember_me && isNotEmpty(remember_token)) {
        remember_token = remember_token + ';' + username
        setRemToken(remember_token)
      }
      //登录成功
      commit('SET_MEMBERINFO', data)
      //设置head cache
      let head_config = data.head_config
      commit('SET_HEADCACHE', head_config)

      setToken(token)
    })
  },
  //获取登录用户信息
  async getMemberInfo({ commit, state }) {
    await getMemberInfo({ token: getToken() }).then(res => {
      const memberInfo = res.data

      commit('SET_MEMBERINFO', memberInfo)
      //设置head cache
      let head_config = memberInfo.head_config
      commit('SET_HEADCACHE', head_config)
    })
  },
  // user logout
  async logout({ commit, state }) {
    await logout(state.token)

    removeToken() // must remove  token  first
    resetRouter()
    commit('RESET_STATE')
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },
  async set_tableColumnCache({ commit, state }, params) {
    const { head_cache } = params

    //设置cookie cache
    if (state.memberInfo) {
      let cache_colum = state.headCache
      if (cache_colum) {
        Object.keys(head_cache).forEach(key => {
          cache_colum[key] = head_cache[key]
        })
      } else {
        cache_colum = head_cache
      }

      //更新cookie本地存储,减少网络延迟导致column更新不及时
      setHeadCache(cache_colum)
      //更新vue数据
      commit('SET_HEADCACHE', cache_colum)
      let cache_column_json = JSON.stringify(cache_colum)
      //发送请求存用户表
      let setParam = {
        token: getToken(),
        head_config: cache_column_json,
      }
      await setDropColumnHead(setParam).then(res => {
        getMemberInfo({ token: getToken() }).then(res => {
          const memberInfo = res.data

          commit('SET_MEMBERINFO', memberInfo)
          //设置head cache
          let head_config = memberInfo.head_config
          commit('SET_HEADCACHE', head_config)
        })
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
