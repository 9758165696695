import { createApp } from 'vue'
import App from './App.vue'
import '../mock/index.js'
// 重置浏览器css样式
import '@/assets/css/reset.css'
import '@/assets/css/editor_reset_revert.css'

//全局样式
import '@/assets/css/commons.css'

//引入awesome icon
import 'font-awesome/css/font-awesome.min.css'

//引入lodash js工具库
import _ from "lodash";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap/dist/css/bootstrap-utilities.css'

import router from './router/index'
import store from './store/index'
// permission control
import '@/permission'

const app = createApp(App)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$host_url =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_SERVE
    : process.env.VUE_APP_SERVE + process.env.VUE_APP_SERVER_NAME

const w_inner = window.innerWidth
app.config.globalProperties.$getComponentSize = w_inner < 480 ? 'mini' : null
app.config.globalProperties.$isMobileTerminal = w_inner < 992 ? true : false
app.config.globalProperties.$btnSize = w_inner < 768 ? 'small' : 'large'

app.config.globalProperties.$mobile_xs = w_inner < 576 ? true : false
app.config.globalProperties.$mobile_sm = w_inner >= 576 && w_inner < 768 ? true : false
app.config.globalProperties.$mobile_md = w_inner >= 768 && w_inner < 992 ? true : false
app.config.globalProperties.$mobile_lg = w_inner >= 992 && w_inner < 1200 ? true : false
app.config.globalProperties.$mobile_xl = w_inner >= 1200 && w_inner < 1400 ? true : false
app.config.globalProperties.$mobile_xxl = w_inner >= 1400 ? true : false


app.config.globalProperties.$_ = _

app.use(router)
app.use(store)
app.mount('#app')
