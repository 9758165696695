<template>
  <el-menu :default-active="pageInfo.menu_activeIndex" :collapse="pageInfo.isCollapse"
    class="el-menu-vertical-demo dashbo-left-menu">
    <template v-for="(rt, index) in routes.children">
      <el-menu-item :index="rt.meta.index" @click="changeRouter(rt.name)"
        v-show="isMenuItemShow(rt) && !rt.meta.hasChild">
        <el-icon>
          <Paperclip />
        </el-icon>
        <span>{{ rt.meta.title }}</span>
      </el-menu-item>

      <el-sub-menu :index="rt.meta.index" v-show="isMenuItemShow(rt) && rt.meta.hasChild">
        <template #title>
          <el-icon>
            <document />
          </el-icon>
          <span>{{ rt.meta.title }}</span>
        </template>

        <el-menu-item :index="rt_child.meta.index" @click="changeRouter(rt_child.name)"
          v-for="(rt_child, index_child) in rt.children" :disabled="rt_child.meta.disabled"
          v-show="isMenuItemShow(rt_child)">
          {{ rt_child.meta.title }}
        </el-menu-item>
      </el-sub-menu>
    </template>

    <el-menu-item @click="goLoginOut">
      <el-icon>
        <SwitchButton />
      </el-icon>
      <span>Logout</span>
    </el-menu-item>
  </el-menu>
</template>

<script setup name="leftNave">
import { ref, reactive, defineProps, computed, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { isEmpty, isNotEmpty } from '@/utils'

const { proxy } = getCurrentInstance()
const router = useRouter()
const route = useRoute()
const store = useStore()
const pageInfo = reactive({
  isCollapse: false,
  menu_activeIndex: '',
  routes: {}
})

pageInfo.isCollapse = proxy.$isMobileTerminal;
const routes = computed(() => {
  let routes = router.options.routes
  routes = routes.filter((item) => {
    return !(item.hidden || false) && item.meta && item.meta.is_member_page == true
  })
  return routes[0] || []
})

onMounted(() => {
  pageInfo.menu_activeIndex = route.meta.index || ''
})

function isMenuItemShow (item = {}) {
  const showingChildren = item.hidden || false
  return !showingChildren;
}

function changeRouter (route_name) {
  router.push({
    name: route_name,
  })
}
function goLoginOut () {
  store.dispatch('member/logout').then(() => {
    router.push({
      name: 'login',
    })
  })
}
</script>

<style lang="scss" scoped>
.dashbo-left-menu {
  height: 100%;

  >li {
    justify-content: start;
  }
}

:deep(.el-menu-item.is-active) {
  color: #fff;
  background: var(--jar-hover-color);
}
</style>
