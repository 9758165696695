<template>
  <el-affix :offset="0">
    <div class="d-none d-lg-block">
      <!--pc client-->
      <el-row ref="ja_header" class="ja_header_container justify-content-between" :class="menu.nav_width_style">
        <el-col :span="menu.is_dashboard ? 3 : 4" class="ps-2 pt-2">
          <el-image :src="require('@/assets/jarv_logo_1.png')" class="ja_image" @click="goRouter('homeMain')"></el-image>
        </el-col>
        <el-col :span="19" :xs="19" :sm="19" :md="19" :lg="19" :xl="19">
          <el-menu :default-active="menu.activeIndex" :ellipsis="false"
            class="ja_menu d-flex justify-content-end align-items-center" mode="horizontal" background-color="#fff"
            :text-color="menu.textColor" active-text-color="#fff" on>
            <el-menu-item index="1" class="ja-menu-item" @click="goRouter('homeMain')">Home</el-menu-item>
            <!-- <el-menu-item index="2" class="ja-menu-item" @click="goRouter('tracking')">Tracking</el-menu-item> -->
            <el-menu-item index="3" class="ja-menu-item" @click="goRouter('logisticsSolutions')">Logistics
              solutions</el-menu-item>
            <el-menu-item index="4" class="ja-menu-item" @click="goRouter('aboutUs')">About Us</el-menu-item>
            <el-menu-item index="5" class="ja-menu-item" @click="goRouter('career')">Career</el-menu-item>
            <el-menu-item index="6" class="ja-menu-item" @click="goRouter('contactUs')">Contact Us</el-menu-item>
            <el-menu-item index="7" class="ja-menu-item" @click="goRouter('onlineBooking')"
              v-if="$store.state.member.memberInfo.login_user">Online Booking</el-menu-item>
          </el-menu>
        </el-col>

        <el-col :span="1" class="text-end cursor-pointer">
          <i class="fa fa-user-circle fs-5" aria-hidden="true" @click="goRouter('login')">
            <span class="text-truncate ms-2" style="max-width: 100px;" v-if="$store.state.member.memberInfo.login_user">{{
              $store.state.member.memberInfo.login_user }}</span>
          </i>
          <!--  <i class="fa fa-sign-out fs-5" aria-hidden="true" @click="loginOut()"></i> -->
        </el-col>
      </el-row>
    </div>
  </el-affix>

  <div class="d-block d-lg-none">
    <!--mobile client-->
    <el-row ref="ja_header" class="ja_header_container_mb justify-content-between">
      <el-col :span="12" :xs="8" :sm="8" :md="10" :lg="12" :xl="12" class="ps-2 d-flex justify-content-start">
        <el-image :src="require('@/assets/jarv_logo_1.png')" class="ja_image" @click="goRouter('homeMain')"></el-image>
      </el-col>
      <el-col :span="12" class="d-flex justify-content-end pe-3 fs-1">
        <i class="fa fa-bars menu-icon-mb" @click="menu.drawer_nav = true" />
        <i class="fa fa-user-circle menu-icon-mb ms-3" aria-hidden="true" @click="goRouter('login')">
          <span class="text-truncate ms-2" style="max-width: 100px;"
            v-if="$store.state.member.memberInfo.login_user && !$mobile_md">{{
              $store.state.member.memberInfo.login_user }}</span>
        </i>
      </el-col>
    </el-row>

    <el-drawer v-model="menu.drawer_nav" title="Menu" direction="ltr" class="ja_drawer_nav w-75" :open-delay="200"
      :close-delay="200">
      <el-menu :default-active="menu.activeIndex" class="ja-menu-mb" mode="vertical" background-color="#fff"
        :text-color="menu.textColor" active-text-color="#fff" on>
        <el-menu-item index="1" class="ja-menu-item-mb" @click="goRouter('homeMain')">Home</el-menu-item>
        <el-menu-item index="3" class="ja-menu-item-mb" @click="goRouter('logisticsSolutions')">Logistics
          solutions</el-menu-item>
        <el-menu-item index="4" class="ja-menu-item-mb" @click="goRouter('aboutUs')">About Us</el-menu-item>
        <el-menu-item index="5" class="ja-menu-item-mb" @click="goRouter('career')">Career</el-menu-item>
        <el-menu-item index="6" class="ja-menu-item-mb" @click="goRouter('contactUs')">Contact Us</el-menu-item>
        <el-menu-item index="7" class="ja-menu-item-mb" @click="goRouter('onlineBooking')"
          v-if="$store.state.member.memberInfo.login_user">Online Booking</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script setup name="headerNav">
import { getCurrentInstance, reactive, ref, onMounted, defineEmits, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter()
const route = useRoute()
const store = useStore()

const languages = [{
  label: "EN",
  value: "en-us"
}]
const siteLanguage = ref('EN')

const menu = reactive({
  drawer_nav: false,
  activeIndex: '',
  textColor: '#fff',

  is_dashboard: false,
  nav_width_style: 'ja_header_container_not_dashboard'
})
let currentInstance = ''
onMounted(async () => {
  currentInstance = getCurrentInstance()
})
watchEffect(() => {
  if (route.meta.is_member_page == true) {
    menu.is_dashboard = true
    menu.nav_width_style = 'ja_header_container_dashboard'
  } else {
    menu.is_dashboard = false
    menu.nav_width_style = 'ja_header_container_not_dashboard'
  }
})
function scrolling () {
  // 滚动条距文档顶部的距离
  let scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop

  if (scrollTop > 0) {
    menu.textColor = '#36435f'
  } else {
    menu.textColor = '#fff'
  }
}

function goRouter (router_name) {
  menu.drawer_nav = false
  router.push({
    name: router_name,
  })
}

function loginOut () {
  store.dispatch('member/logout')
}

</script>

<style scoped lang="scss">
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: var(--jar-menu-active-color) !important;
}

:deep(.el-menu--horizontal>.el-menu-item) {
  color: var(--jar-theme-color);
  border-bottom: 2px solid var(--jar-menu-active-color);
  border-color: rgba(0, 153, 0, 0);
}

:deep(.el-menu--horizontal>.el-menu-item.is-active) {
  color: var(--jar-menu-active-color) !important;
  border-bottom: 2px solid var(--jar-menu-active-color);
  border-color: rgba(0, 153, 0, 0);
}

:deep(.el-sub-menu__title) {
  background: rgba(251, 251, 251, 0);
}

.ja-sub-menu-item:hover {
  border: none;
}

/* pc css */
.ja_header_container_not_dashboard {
  max-width: 1280px;
}

.ja_header_container_dashboard {
  width: 90%;
}

.ja_header_container {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 86px;

  .ja_image {
    display: block;
  }

  .ja_menu {
    height: 100%;
    color: var(--jar-theme-color) !important;
    border: none;
    background: rgba(251, 251, 251, 0) !important;


    .ja-menu-item {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 1400px) {
      .ja-menu-item {
        font-size: 1.125rem;
      }
    }

    @media screen and (max-width: 1200px) {
      .ja-menu-item {
        font-size: 1rem;
      }
    }

    .ja-menu-item:hover,
    .ja-menu-item:focus {
      background-color: unset !important;
    }
  }

  .ja-rightbox {
    font-size: 20px;
    color: #fff;
  }

  .ja-downmenu {
    font-size: 18px;
    color: #fff;
  }

  .ja-rightbox_0 {
    font-size: 20px;
    color: #36435f;
  }

  .ja-downmenu_0 {
    font-size: 18px;
    color: #36435f;
  }

  .ja-downmenu .el-dropdown-link {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

/* mobile css */
.ja_header_container_mb {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 86px;
}

.menu-icon-mb {
  font-size: 1.5rem;
}

:deep(.el-drawer__title) {
  font-size: 1.625rem;
  color: var(--jar-theme-color);
}


.ja_drawer_nav {
  .ja-menu-mb {
    border-right: unset;
  }

  .ja-menu-item-mb:hover,
  .ja-menu-item-mb:focus {
    background-color: unset !important;
  }

  .ja-menu-item-mb {
    color: var(--jar-theme-color) !important;
  }

  .ja-menu-item-mb.is-active {
    color: var(--jar-menu-active-color) !important;
  }
}
</style>