//路由管理器配置文件，默认配置文件名：index.js
// 引入路由器
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router'
import routes from './routes'
// import store from '@/store'
import { getToken } from '@/utils/auth' // get token from cookie
import { ElMessageBox, ElMessage } from 'element-plus'

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
})

/* // 重写编程式路由跳转方法，push 、 replace
const orignPush = VueRouter.prototype.push
const orignReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    orignPush.call(this, location, resolve, reject)
  } else {
    orignPush.call(
      this,
      location,
      () => {},
      () => {}
    )
  }
}

VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    orignReplace.call(this, location, resolve, reject)
  } else {
    orignReplace.call(
      this,
      location,
      () => {},
      () => {}
    )
  }
} */

// reset router
export function resetRouter() {
  const newRouter = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
  })
  router.matcher = newRouter.matcher // reset router
}

// 暴露router对象
export default router
