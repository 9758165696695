//定义路由器配置对象
//路由配置，name、path、component、meta。。。
//引入需要配置路由的路由组件(懒加载)，@： 配置的src别名
import Layout from '@/views/Dashboard/layout'
import LayoutSingle from '@/views/Dashboard/layoutSingle'

export default [
  /* 前台路由 */
  {
    path: '/',
    redirect: '/home',
    hidden: true,
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true,
    meta: {
      title: '404',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login'),
    hidden: true,
    meta: {
      title: 'Login',
      redirect: '/member/dashboard',
    },
  },
  {
    name: 'forgotpassword',
    path: '/forgotpassword',
    component: () => import('@/views/Forgotpassword'),
    hidden: true,
    meta: {
      title: 'forgotpassword',
    },
  },
  {
    name: 'restforgotpassword',
    path: '/restforgotpassword/:rest_token',
    component: () => import('@/views/Forgotpassword'),
    hidden: true,
    meta: {
      title: 'forgotpassword',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'homeMain',
    // 路由路径
    path: '/home',
    // 路由组件
    component: () => import('@/views/homePage/homePage'),
    // 路由特殊持有参数
    meta: {
      title: 'Home',
      channel_name: 'home',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'tracking',
    // 路由路径
    path: '/tracking',
    // 路由组件
    component: () => import('@/views/homePage/tracking'),
    // 路由特殊持有参数
    meta: {
      title: 'Tracking',
      channel_name: 'tracking',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'logisticsSolutions',
    // 路由路径
    path: '/logistics-solutions',
    // 路由组件
    component: () => import('@/views/homePage/logisticsSolutions'),
    // 路由特殊持有参数
    meta: {
      title: 'Logistics Solutions',
      channel_name: 'logistics',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'aboutUs',
    // 路由路径
    path: '/about-us',
    // 路由组件
    component: () => import('@/views/homePage/about'),
    // 路由特殊持有参数
    meta: {
      title: 'About Us',
      channel_name: 'about',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'career',
    // 路由路径
    path: '/career',
    // 路由组件
    component: () => import('@/views/homePage/career'),
    // 路由特殊持有参数
    meta: {
      title: 'Career',
      channel_name: 'career',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'register',
    // 路由路径
    path: '/register',
    // 路由组件
    component: () => import('@/views/homePage/register'),
    // 路由特殊持有参数
    meta: {
      title: 'Register',
      channel_name: 'register',
    },
  },
  {
    //路由名称, 编程式路由跳转使用
    name: 'contactUs',
    // 路由路径
    path: '/contact-us',
    // 路由组件
    component: () => import('@/views/homePage/contact'),
    // 路由特殊持有参数
    meta: {
      title: 'Contact Us',
      channel_name: 'contact',
    },
  },
  {
    name: 'onlineBooking',
    path: '/online_booking',
    component: () => import('@/views/homePage/onlineBooking'),
    meta: {
      title: 'Online Booking',
      channel_name: 'online_booking',
    },
  },
  {
    path: '/member',
    name: 'member',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      is_member_page: true,
      hasChild: true,
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/views/Dashboard/dashboard'),
        meta: {
          is_member_page: true,
          pageLoading: false,
          title: 'Dashboard',
          icon: 'el-icon-menu',
          index: '1',
          hasChild: false,
        },
      },
      {
        name: 'orders',
        path: 'orders',
        meta: {
          is_member_page: true,
          pageLoading: false,
          title: 'Orders',
          icon: 'el-icon-menu',
          index: '2',
          hasChild: true,
        },
        redirect: '/member/orders/booking_orders',
        children: [
          {
            name: 'bookingOrders',
            path: 'booking_orders',
            component: () => import('@/views/Dashboard/orderEntry'),
            meta: {
              order_type: 'booking_order',
              pageLoading: false,
              title: 'Booking Orders',
              icon: 'el-icon-menu',
              index: '2-1',
              hasChild: false,
            },
          },
          {
            name: 'oceanShipments',
            path: 'ocean_orders',
            component: () => import('@/views/Dashboard/orderEntry'),
            meta: {
              order_type: 'ocean_order',
              pageLoading: false,
              title: 'Ocean Shipments',
              icon: 'el-icon-menu',
              index: '2-2',
              hasChild: false,
            },
          },
        ],
      },
      {
        name: 'online_booking',
        path: 'online_booking',
        meta: {
          is_member_page: true,
          pageLoading: false,
          title: 'Online Booking',
          icon: 'el-icon-menu',
          index: '3',
          hasChild: true,
        },
        redirect: '/member/online_booking/ace_m1',
        children: [
          {
            name: 'ace_m1',
            path: 'ace_m1',
            hidden: false,
            component: () => import('@/views/Dashboard/onlineBooking'),
            meta: {
              order_type: 'ace_m1',
              pageLoading: false,
              title: 'Ace Form',
              icon: 'el-icon-menu',
              index: '3-1',
              hasChild: false,
            },
          },
          {
            name: 'shippingOrder',
            path: 'shipping_order',
            hidden: false,
            component: () => import('@/views/Dashboard/onlineBooking'),
            meta: {
              order_type: 'shipping_order',
              pageLoading: false,
              title: 'Shipping Order',
              icon: 'el-icon-menu',
              index: '3-2',
              hasChild: false,
            },
          },
          {
            name: 'isfForms',
            path: 'isf_form',
            hidden: false,
            component: () => import('@/views/Dashboard/onlineBooking'),
            meta: {
              order_type: 'isf_form',
              pageLoading: false,
              title: 'ISF Form',
              icon: 'el-icon-menu',
              index: '3-3',
              hasChild: false,
            },
          },
          {
            name: 'editOnlineBooking',
            path: 'edit_online_booking',
            hidden: true,
            component: () =>
              import('@/views/Dashboard/onlineBooking/editOnlineBooking'),
            meta: {
              index: '2-3',
            },
          },
        ],
      },
      {
        name: 'changePassword',
        path: 'changePassword',
        component: () => import('@/views/Dashboard/profile/changePassword'),
        meta: {
          is_member_page: true,
          pageLoading: false,
          title: 'Change Password',
          icon: 'el-icon-menu',
          index: '4',
          hasChild: false,
        },
      },
    ],
  },
]
