//仓库数据
const state = {
  navActiveIndex: '1',
}
//唯一修改仓库数据
const mutations = {
}
//处理action，业务逻辑，处理异步
const actions = {
  //此处不能修改仓库数据，只能书写业务逻辑
}
//理解为计算属性，简化仓库数据获取，比如 state.b.c.d.e   =>    _getter().e
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
