import Mocks from 'mockjs'
import { getSuccess } from '../utils/Result'

// 获取 mock.Random 对象
const baseUrl = '/ceshi'

const tipList = [
  {
    id: 101,
    tip: '今天吃什么呢',
    content: '饺子',
  },
  {
    id: 102,
    tip: '明天吃什么呢',
    content: '大米饭',
  },
  {
    id: 103,
    tip: '后天吃什么呢',
    content: '玉米',
  },
  {
    id: 104,
    tip: '在墓地里面吃什么呢',
    content: '啃土',
  },
]

const article = Mocks.mock(baseUrl + '/api/article', () => {
  return getSuccess(tipList)
})

export default router => {
  return [router.get(baseUrl + '/api/article', article)]
}
