<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in pageInfo.levelList" :key="item.path">
        <span v-if="item.meta.redirect==='noRedirect'||index== pageInfo.levelList.length-1"
              class="no-redirect">{{ item.meta.title }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
  import pathToRegexp from 'path-to-regexp'
import { ref, reactive, defineProps, computed, onMounted ,watch} from 'vue'
import { useRouter, useRoute } from 'vue-router'

const route = useRoute()
const router = useRouter()
const pageInfo = reactive({
     levelList: null,
})

getBreadcrumb()

watch(route,() => { 
  getBreadcrumb()
})

function getBreadcrumb() {
  // only show routes with meta.title
  let matched = route.matched.filter(
    (item) => item.meta && item.meta.title
  )

  const first = matched[0]

  if (!isDashboard(first)) {
    matched = [{ path: '/dashboard', meta: { title: 'Dashboard' } }].concat(
      matched
    )
  }

  pageInfo.levelList = matched.filter(
    (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
  )
}
function isDashboard(route) {
  const name = route && route.name
  if (!name) {
    return false
  }
  return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
}
function pathCompile(path) {
  // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
  const { params } = this.$route
  router.push({ name: 'dashboard' })
}
function handleLink(item) {
  const { redirect, path } = item
  if (redirect) {
    router.push(redirect)
    return
  }
  router.push(pathCompile(path))
}
</script>

<style lang="scss" scoped>
  .app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    margin-bottom: 20px;

    .no-redirect {
      color: var(--jar-menu-active-color);
      cursor: text;
    }
  }
</style>
