import moment from 'moment'

let code = 20000
let message = '操作成功!'
let success = true
let timestamp = moment().millisecond()
let data = {}

function getResult() {
  return { code, message, success, timestamp }
}

/*
 * 返回成功 success message code
 * */
export function getSuccess(data) {
  let result = getResult()
  result.data = data
  return result
}

/*
 * 返回失败 success message code
 * */
export function getError() {
  let result = getResult()
  result.data = {}
  result.message = '操作失败'
  result.code = 20001
  return result
}
