import router from './router'
import store from './store'

import { ElMessage } from 'element-plus'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/pageInfo'
import { isNotEmpty, isEmpty,showMessageTips } from '@/utils'

NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['/login'] // no redirect whitelist

//路由器前置守卫，应用与权限管理
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the member has logged in
  const hasToken = getToken()

  //获取登录用户信息，vuex数据不持久化
  if (hasToken) {
    //登录标记通过
    const hasMemberInfo = store.getters.memberName
    if (isEmpty(hasMemberInfo)) {
      try {
        // get user info
        await store.dispatch('member/getMemberInfo')
      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch('member/resetToken')
      }
    }
  }

  if (to.path === '/online_booking' && !hasToken) {
    //logistics 先登录查看
    to.redirect = '/tracking'
    next({ path: '/login' })
    NProgress.done()
  } else if (to.path === '/tracking' && !hasToken) {
    /* //logistics 先登录查看
    to.redirect = '/tracking'
    next({ path: '/login' }) */

    next()
    NProgress.done()
  } else if (to.path === '/login') {
    if (hasToken) {
      // if is logged in, redirect to the home page
      next({ path: '/member/dashboard' })
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  } else {
    next()
    NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
