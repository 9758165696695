/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {any}   isEmpty
 * @returns {boolean}
 */
// 检查字符串是否为空
const checkStringEmpty = str => {
  return !str
}
// 检查数值是否为空
const checkNumberEmpty = num => {
  return !num.toString()
}
// 检查对象是否为空
const checkObjectEmpty = obj => {
  return Object.keys(obj).length === 0
}
// 检查数组是否为空
const checkArrayEmpty = arr => {
  return arr.length === 0
}
// 各数据类型检查方法字典
const checkDataEmptyDict = {
  string: checkStringEmpty,
  number: checkNumberEmpty,
  Object: checkObjectEmpty,
  Array: checkArrayEmpty,
  function: () => false,
  undefined: () => true,
  boolean: data => !data,
  Null: () => true,
}
/**
 * @description 检查数据是否为空，为空返回true，不为空返回false
 * @param {any} data
 * @return {Boolean}
 * @example isEmpty({}) ⇒ true
 */
const isEmpty = data => {
  let type = typeof data
  // 基础数据类型
  if (type !== 'object') return checkDataEmptyDict[type](data)
  // 引用数据类型
  type = Object.prototype.toString
    .call(data)
    .replace(/^\[object (\S+)\]$/, '$1')
  return checkDataEmptyDict[type](data)
}
const isNotEmpty = data => {
  return !isEmpty(data)
}
export { isEmpty, isNotEmpty }

import { ElMessage } from 'element-plus'
export function showMessageTips(message, type, callback) {
  if (isEmpty(type)) {
    type = 'warning'
  }
  ElMessage({
    message: message,
    type: type,
    duration: 5 * 1000,
    onClose: () => {
      //判定callback接收到的数据是一个函数
      if (typeof callback === 'function') {
        //callback是一个函数，才能当回调函数使用
        callback()
      }
    },
  })
}
// 设置表格中每行的唯一ID
export function setListKey(index) {
  var key =
    (Math.random() * 10000000).toString(16).substr(0, 4) +
    '-' +
    new Date().getTime() +
    '-' +
    Math.random().toString().substr(2, 5) +
    '-' +
    index.toString()
  return key
}

export function getImageSize(url) {
  return new Promise((resolve, reject) => {
    let image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      })
      image.onerror = () => {
        reject(new Error('error image'))
      }
      image.src = url
    }
  })
}

//1、这个函数是，判断图片格式--------------------------------------------------------------------
export function isAssetTypeAnImage(filePath) {
  //获取最后一个.的位置
  var index = filePath.lastIndexOf('.')
  //获取后缀
  var ext = filePath.substring(index + 1)

  return (
    ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
      ext.toLowerCase()
    ) !== -1
  )
}

//2、这个函数是，判断视频格式---------------------------------------------------------------
export function isAssetTypeAnTV(filePath) {
  //获取最后一个.的位置
  var index = filePath.lastIndexOf('.')
  //获取后缀
  var ext = filePath.substring(index + 1)

  return ['mp4', 'rmvb', 'avi', 'ts'].indexOf(ext.toLowerCase()) !== -1
}
