const getters = {
  memberName: state => {
    return state.member.memberInfo.login_user
  },
  memberInfo: state => {
    return state.member.memberInfo
  },
  headCache: state => {
    return state.member.headCache
  },
}
export default getters;
