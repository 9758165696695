import getters from "./getters";
import member from './modules/member'
import web_page from './modules/web_page'

import { createStore } from 'vuex'

export default createStore({
  //实现vuex模块式存储
  modules: {
    member,
    web_page,
  },
  getters,
})


