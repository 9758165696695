<template>
  <div class="justify-content-center track-box" v-show="trackBoxShow">
    <div :span="24" class="mt-4">
      <el-button :size="$btnSize" type="primary" @click="goToRouter('tracking')">TRACK MY SHIPMENT</el-button>
    </div>
  </div>
  <el-row ref="footerNavBox" class="ja-footer-container h-100">
    <el-col :span="24" class="h-100">
      <el-row class="ja-footer-box justify-content-between" :class="{ 'h-100': !$isMobileTerminal }">
        <el-col :xs="0" :sm="0" :md="0" :lg="0" :xl="1" class="d-flex align-items-center">
        </el-col>

        <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="8" class="d-flex align-items-center ps-3"
          :class="{ 'mt-3': $isMobileTerminal , 'pt-3': $isMobileTerminal }">
          <el-row class="text-start w-100">
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="6">Company Address:</el-col>
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="18">400 Oyster Point Blvd Ste526, South San
              Francisco, CA 94080</el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4" class="d-flex align-items-center ps-3"
          :class="{ 'mt-3': $isMobileTerminal }">
          <el-row class="text-start w-100">
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="6">Email:</el-col>
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="18">info@jarvislogistics.com</el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4" class="d-flex align-items-center ps-3"
          :class="{ 'mt-3': $isMobileTerminal }">
          <el-row class="text-start w-100">
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="6">Phone:</el-col>
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="18">+1 650-745-8243</el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="d-flex align-items-center ps-3"
          :class="{ 'mt-3': $isMobileTerminal, 'bg-dark': !$isMobileTerminal, 'pb-3': $isMobileTerminal }">Copyright ©
          2023
          Jarvis International Freight LLC</el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script setup name="footerNav">
import { getCurrentInstance, reactive, ref, onMounted, defineEmits, onUpdated, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { isNotEmpty  } from '@/utils';
const route = useRoute()
const router = useRouter()

const footerNavBox = ref('')
const trackBoxShow = ref(true)

watchEffect(() => {
  if (trackBoxShow.value == true) {
    if (isNotEmpty(route.meta) && route.meta.is_member_page) {
      trackBoxShow.value = false
    }
  }
})
onMounted(() => {
  let footnav_height = footerNavBox.value.$el.clientHeight
  const dom = document.getElementById('app');
  const clientHeight = window.innerHeight;

  window.onload = function () {
    let scrollTop = window.scrollY
    const scrollHeight = dom.scrollHeight;

    if (clientHeight + scrollTop <= scrollHeight - footnav_height) {
      trackBoxShow.value = true
    } else {
      trackBoxShow.value = false
    }
  }
  window.addEventListener('scroll', () => {
    let scrollTop = window.scrollY
    const scrollHeight = dom.scrollHeight;

    if (clientHeight + scrollTop <= scrollHeight - footnav_height) {
      trackBoxShow.value = true
    } else {
      trackBoxShow.value = false
    }
  })
})

function goToRouter (router_name) {
  router.push({
    name: router_name,
  })
}
</script>

<style scoped lang="scss">
$footer_bacc: #0a1d55;

.track-box {
  position: fixed;
  bottom: 0;
  z-index: 111;
  width: 100%;
  color: #fff;
  height: 5.625rem;
  /* background-color: #36435f; */
  background: rgba(54, 67, 95, 0.5);
  // background:linear-gradient(#99d9ea 50%,#00a2e8 50%);
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
}

.el-menu-item:hover {
  color: var(--jar-menu-active-color) !important;
  border-bottom: 2px solid #0a1d55;
}

:deep(.el-menu--horizontal>.el-menu-item.is-active) {
  color: #fff !important;
  border-bottom: 2px solid #0a1d55;
  border-color: rgba(0, 153, 0, 0);
}

:deep(.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover) {
  background-color: #0a1d55;
  color: #fff !important;
  border-bottom: 2px solid #0a1d55;
  border-color: rgba(0, 153, 0, 0);
}

:deep(.el-sub-menu__title) {
  background: rgba(251, 251, 251, 0) !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.ja-footer-box {
  width: 100%;
  align-items: center;
  /* 垂直居中 */
  justify-content: center;
  color: var(--jar-white-color);

  >div {
    min-height: 140px;
    height: 100%;
  }
}

.ja-footer-container {
  >div {
    background: var(--jar-theme-color);
  }
}

@media only screen and (max-width: 768px) {
  .ja-footer-box div {
      min-height: 25px;
  }

  .track-box {
    height: 4rem;
  }
}


.footer-nav {
  display: flex;
  height: 100%;
  background-color: $footer_bacc;
  align-items: center;
  /* 垂直居中 */
  justify-content: center;
  /* 水平居中 */

  .footer-nav-item {
    color: #fff;
    float: none;
    user-select: none;
  }

  .footer-nav-item:hover {
    background-color: $footer_bacc !important;
    color: #fff;
  }

  .is-active,
  .is-active:active {
    border-bottom: none !important;
  }

  .is-active:not(.is-disabled):focus,
  .is-active:not(.is-disabled):hover {
    color: #fff;
    background-color: $footer_bacc;
  }

  .el-menu--horizontal>.el-menu-item {
    border-bottom: unset;
  }
}
</style>
