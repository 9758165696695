import { ElLoading } from 'element-plus'
import _ from "lodash";
import { isNotEmpty } from ".";

let loading = null;
let needRequestCount = 0;

//开启loading状态
const startLoading = (headers = {}) => {
  loading = ElLoading.service({
    lock: true, //是否锁定屏幕的滚动
    text: headers.text || 'Loading...', //loading下面的文字
    background: 'rgba(0, 0, 0, 0.7)', //loading的背景色
    target: headers.target || 'body', //loading显示在容器
  })
};

//关闭loading状态
//在关闭loading为了防止loading的闪动，采用防抖的方法，防抖计时一般采用300-600ms
//在关闭loading之后，我们需注意全局变量导致的V8垃圾回收机制，把没用的变量清空为null
const endLoading = _.debounce(() => {
  if (isNotEmpty(loading)) {
    //延迟600关闭loading
    setTimeout(() => { 
      loading.close();
      loading = null;
    },200)
  }
}, 300);

export const showScreenLoading = (headers) => {
  if (needRequestCount == 0 && !loading) {
    startLoading(headers);
  }
  needRequestCount++;
};

export const hideScreenLoading = () => {
  if (needRequestCount <= 0) return;
  needRequestCount--;
  needRequestCount = Math.max(needRequestCount, 0);
  if (needRequestCount === 0) {
    endLoading();
  }
};
export default {};
