<template>
  <div class="common-layout">
    <el-backtop :right="$isMobileTerminal ? 20 : 100" :bottom="100" />

    <el-container v-if="!pageConst.loading">
      <el-header ref="headerBox" class="d-none d-md-block ja-header" height="100px" v-show="pageConst.show_header_nav"
        @showHeader="showHeader">
        <HeaderNav></HeaderNav>
      </el-header>
      <el-header class="d-block d-md-none ja-header-mb" height="86px" v-show="pageConst.show_header_nav"
        @showHeader="showHeader">
        <HeaderNav></HeaderNav>
      </el-header>

      <el-main class="ja-main">
        <!-- 路由展示区 -->
        <router-view v-if="isRouterActive"></router-view>
      </el-main>

      <el-footer class="ja-footer mt-3">
        <footerNav></footerNav>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { reactive, ref, onMounted, provide, nextTick, getCurrentInstance, watchEffect } from 'vue';
import HeaderNav from '@/components/headerNav.vue'
import footerNav from '@/components/footerNav.vue'
import { useRoute, useRouter } from 'vue-router';
import { isEmpty, isNotEmpty } from '@/utils'

export default {
  name: 'App',
  components: {
    HeaderNav,
    footerNav
  },
  setup () {
    const route = useRoute()
    const { proxy } = getCurrentInstance()

    const isRouterActive = ref(true)

    const headerBox = ref('')
    const pageConst = reactive({
      loading: false,
      scrolled_header: false,
      show_header_nav: true
    })

    provide('reload', () => {
      isRouterActive.value = false
      nextTick(() => {
        isRouterActive.value = true
      })
    })
    watchEffect(() => {
      if (isNotEmpty(route.matched) && route.matched[0].meta.is_member_page) {
        // ja - header
      }
    })

    onMounted(async () => {
      /* await nextTick(() => {
          setTimeout(() => {
              loading.value = false
          }, 2000);
      }) */

      // 监听页面滚动事件
      //window.addEventListener('scroll', scrolling)
      let base_px = 22
      window.addEventListener('load', () => {
        if (proxy.$isMobileTerminal) {
          var deviceWidth = document.documentElement.clientWidth || document.body.clientWidth;
          if (deviceWidth > 750) {
            deviceWidth = 750;
          }
          document.documentElement.style.fontSize = (deviceWidth / 750) * base_px + "px";
        }
      })
      window.addEventListener('resize', () => {
        const w_inner = window.innerWidth
        proxy.$getComponentSize =
          w_inner < 480 ? 'mini' : null
        proxy.$isMobileTerminal =
          w_inner < 992 ? true : false

        proxy.$mobile_xs =
          w_inner < 576 ? true : false
        proxy.$mobile_sm =
          (w_inner >= 576 && w_inner < 768) ? true : false
        proxy.$mobile_md =
          (w_inner >= 768 && w_inner < 992) ? true : false
        proxy.$mobile_lg =
          (w_inner >= 992 && w_inner < 1200) ? true : false
        proxy.$mobile_xl =
          (w_inner >= 1200 && w_inner < 1400) ? true : false
        proxy.$mobile_xxl =
          w_inner >= 1400 ? true : false

        proxy.$btnSize =
          w_inner < 768 ? 'small' : 'large'

        if (proxy.$isMobileTerminal) {
          var deviceWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
          if (deviceWidth > 750) {
            deviceWidth = 750;
          } document.documentElement.style.fontSize = (deviceWidth / 750) * base_px + "px";
        }
      })
    })

    /* function scrolling () {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      if (scrollTop > 0) {
        pageConst.scrolled_header = true
      } else {
        pageConst.scrolled_header = false
      }
    } */

    function showHeader (header_status) {
      pageConst.show_header_nav = header_status
    }
    return { isRouterActive, pageConst, showHeader, headerBox }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'MontserratRegular', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #36435f !important;
  background-color: #eff2f7;
  font-size: 0.875rem;
}

.ja-header {
  padding: unset !important;
  background: rgba(251, 251, 251, 0.8);
  color: var(--jar-theme-color) !important;
  position: fixed;
  z-index: 999;
  width: 100%;
}

.ja-header-no-fixed {
  padding: unset !important;
  background: rgba(251, 251, 251, 0.8);
  color: var(--jar-theme-color) !important;
  position: relative;
  width: 100%;
}

.ja-header-mb {
  padding: unset !important;
  background: rgba(251, 251, 251, 0.8);
  color: var(--jar-theme-color) !important;
  z-index: 999;
  width: 100%;
}

.ja-main {
  padding: unset !important;
  overflow: hidden !important;
  min-height: 100vh;

  .ja-skeleton-circle {
    margin-bottom: 12px;
    width: 48px;
    height: 48px;
    background-color: #c2c0c0;
  }

  .ja-skeleton-text {
    padding: 12px 14px 0 0
  }

  .ja-skeleton-image {
    width: 100%;
    height: 240px;
    background-color: #c2c0c0;
    margin-top: 15px
  }
}

.ja-footer {
  height: unset !important;
  padding: 0 !important;
}
</style>
