import reqeusts from '@/utils/request'

export const getCatchCode = params =>
  reqeusts.request({
    url: '/member/getVerifyCode',
    method: 'get',
    params,
  })
export const login = params =>
  reqeusts.request({
    url: '/member/login',
    method: 'post',
    data: params,
  })
export const getMemberInfo = params =>
  reqeusts.request({
    url: '/member/getMemberInfo',
    method: 'post',
    data: params,
  })
export const logout = token =>
  reqeusts.request({
    url: '/member/loginOut',
    method: 'get',
    params: {
      token,
    },
  })
export const changePassword = params =>
  reqeusts.request({
    url: '/member/changePassword',
    method: 'post',
    data: params,
  })
export const getOrders = params =>
  reqeusts.request({
    url: '/member/getOrders',
    method: 'post',
    data: params,
  })
export const getOrderDetail = params =>
  reqeusts.request({
    url: '/member/getOrderDetail',
    method: 'post',
    data: params,
  })

export const setDropColumnHead = params =>
  reqeusts.request({
    url: '/member/setDropColumnHead',
    method: 'post',
    data: params,
  })

export const checkRestToken = params =>
  reqeusts.request({
    url: '/member/checkResetToken',
    method: 'post',
    data: params,
  })
export const forgotPassword = params =>
  reqeusts.request({
    url: '/member/forgotPassword',
    method: 'post',
    data: params,
  })
export const setForgotPassword = params =>
  reqeusts.request({
    url: '/member/setForgotPassword',
    method: 'post',
    data: params,
  })

export const getConsignees = params =>
  reqeusts.request({
    url: '/member/getConsignees',
    method: 'post',
    data: params,
  })

export const getExportColumn = params =>
  reqeusts.request({
    url: '/member/getExportColumns',
    method: 'post',
    data: params,
  })
export const saveExportColumn = params =>
  reqeusts.request({
    url: '/member/setExportColumns',
    method: 'post',
    data: params,
  })
